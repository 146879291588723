import React, { Component } from "react"
import Layout from "../components/layout/layout"

class IndexPage extends Component {
  state = {
    smallScreen: false,
    modal: { name: "" },
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this))
    this.resize()
  }

  resize() {
    this.setState({ smallScreen: window.innerWidth <= 840 })
  }

  render() {
    let indexData = this.props.data.allIndexJson.nodes[0]
    let homeItems = []
    let i = 0
    indexData.home_items.forEach(item => {
      homeItems.push(
        <a
          href={item.url}
          key={i}
          className={
            this.state.smallScreen ? "grid-item-small" : "home-grid-item"
          }
          title={item.title}
          style={{
            backgroundImage: `url(${item.image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "none",
          }}
        ></a>
      )
      i++
    })
    return (
      <Layout page={"home"} title={indexData.title}>
        <p className="description_text">{indexData.text}</p>
        <hr className="divider" />
        <h2 className="subtitle">{indexData.subtitle}</h2>
        <div
          className={
            this.state.smallScreen
              ? "grid-container-small"
              : "home-grid-container"
          }
        >
          {homeItems}
        </div>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query {
    allIndexJson {
      nodes {
        title
        description
        text
        subtitle
        home_items {
          title
          image
          url
        }
      }
    }
  }
`
